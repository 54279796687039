import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";

@Injectable()
export class StorageService {

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
  }

  setItem(key: string, value: any, isJson = true): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(key, isJson ? JSON.stringify(value) : value);
    }
  }

  getItem(key: string, isJson = true): any {
    if (isPlatformBrowser(this.platformId)) {
      const item = localStorage.getItem(key);
      if (item) {
        return isJson ? JSON.parse(item) : item;
      }
    }
    return null;
  }

  removeItem(key: string): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(key);
    }
  }

  clear(): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.clear();
    }
  }
}
